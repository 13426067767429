//============================================
// Klavier Klasse
//============================================

class Klavier {
  constructor(tasten, form) {
    this.tasten = tasten;
    this.form = form;
    this.index = 0;
    this.tooltipIsShowing = false;

    this.notes = [
      this.form.tone01,
      this.form.tone02,
      this.form.tone03,
      this.form.tone04,
      this.form.tone05,
      this.form.tone06
    ];
  }

  keydown(e) {
    if (document.body.classList.contains("modal--active")) return;
    this.tasten.forEach(taste => {
      let key = parseInt(taste.dataset.keycode);
      let note = taste.dataset.note.toUpperCase();
      if (e.keyCode === key) {
        taste.classList.add("active");
        let sound = new Howl({
          src: ["./assets/audio/" + note + ".mp3"],
          loop: false,
          onend: function() {
            sound.stop();
            return;
          }
        });
        this.formular(taste);
        sound.play();
      }
    });
  }

  keyup() {
    this.tasten.forEach(taste => taste.classList.remove("active"));
  }

  mousedown(e) {
    if (e.repeat == true) return;
    let note = taste.dataset.note.toUpperCase();
    let sound = new Howl({
      src: ["./assets/audio/" + note + ".mp3"],
      loop: false,
      onend: function() {
        sound.stop();
        return;
      }
    });

    this.formular(taste);
    sound.play();
  }

  resetIndex() {
    this.index = 0;
  }

  touchstart(event) {
    this.tasten.forEach(taste => {
      taste.addEventListener(event, e => {
        if (e.repeat) return;
        e.preventDefault;
        let note = taste.dataset.note.toUpperCase();
        let sound = new Howl({
          src: ["./assets/audio/" + note + ".mp3"],
          loop: false,
          onend: function() {
            sound.stop();
            return;
          }
        });

        this.formular(taste);
        sound.play();
      });
    });
  }

  formular(el) {
    let inputValue = el.dataset.note.replace("1", "");
    if (this.index < this.notes.length) {
      this.notes[this.index].value = inputValue.toUpperCase();
      this.index++;
    }
  }

  resetForm(e) {
    e.preventDefault();
    this.notes.forEach(note => (note.value = ""));
    this.index = 0;
  }

  tooltip(el) {
    let selectElement = document.querySelector(el);
    selectElement.addEventListener("click", e => {
      this.tooltipIsShowing = !this.tooltipIsShowing;
      this.tooltipIsShowing
        ? (selectElement.innerHTML = "<span>?</span>  Tastenbelegung verbergen")
        : (selectElement.innerHTML = "<span>?</span>  Tastenbelegung anzeigen");
      this.tasten.forEach(taste => {
        let tooltip = document.createElement("span");
        tooltip.className = "tooltip";
        tooltip.innerText = taste.dataset.key;
        this.tooltipIsShowing
          ? taste.appendChild(tooltip)
          : (taste.innerHTML = "");
      });
    });
  }

  openModal(modal) {
    if (this.index >= 6) {
      document.body.classList.add("modal--active");
      modal.style.display = "block";
      const code =
        this.form.tone01.value +
        this.form.tone02.value +
        this.form.tone03.value +
        this.form.tone04.value +
        this.form.tone05.value +
        this.form.tone06.value;
      modal.querySelector("#code").innerText = code;
    } else {
      modal.style.display = "none";
    }
    modal.querySelector("#closeModal").addEventListener("click", () => {
      modal.style.display = "none";
      document.body.classList.remove("modal--active");
    });
  }

  successNotification(id) {
    if (document.querySelector(".sucess--message")) {
      document.querySelector(".sucess--message").remove();
    }
    const success = `
      <div class="sucess--message">
        <p>Danke für Ihre Teilnahme, wir drücken Ihnen ganz fest die Daumen! Ob Ihre Melodie gewinnt, erfahren Sie per E-Mail circa 1 Woche vor Konzertbeginn.</p>
      </div>
    `;
    document.getElementById(id).insertAdjacentHTML("beforebegin", success);
  }
}

if (document.getElementById("klavier")) {
  //============================================
  // Neue Klavier Instanz / Events
  //============================================

  const klavierForm = document.getElementById("klavier--form");
  const tasten = klavierForm.querySelectorAll("[data-keycode]");
  const resetButton = klavierForm.querySelector("#resetButton");
  const modal = document.getElementById("modal");
  const next = document.getElementById("nextStep");
  const Keyboard = new Klavier(tasten, klavierForm);
  const sendData = document.getElementById("sendData");
  const sendDataButton = document.getElementById("sendDataButton");
  const errorMsg = document.getElementById("error_message");

  next.addEventListener("click", e => {
    e.preventDefault();
    Keyboard.openModal(modal);
  });

  window.addEventListener("keydown", e => {
    if (e.repeat === true) return;
    Keyboard.keydown(e);
  });
  window.addEventListener("keyup", () => Keyboard.keyup());
  Keyboard.touchstart("touchstart");
  Keyboard.touchstart("mousedown");
  Keyboard.tooltip(".key--tipp");
  resetButton.addEventListener("click", e => {
    Keyboard.resetForm(e);
  });

  const inputs = document.getElementsByTagName("input");

  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener("focus", ev => (errorMsg.innerHTML = ""));
  }

  sendDataButton.addEventListener("click", e => {
    e.preventDefault();

    const veranstaltungsort = document.querySelector(".va--ort").innerHTML;

    const code =
      klavierForm.tone01.value +
      klavierForm.tone02.value +
      klavierForm.tone03.value +
      klavierForm.tone04.value +
      klavierForm.tone05.value +
      klavierForm.tone06.value;
    let date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    let hours = date.getHours();
    let mins = date.getMinutes();
    let secs = date.getSeconds();
    date = `${day}.${month}.${year}, ${hours}:${mins}:${secs}`;
    const data = {
      code: code,
      veranstaltungsort,
      ort: sendData.ort.value,
      vorname: sendData.vorname.value,
      nachname: sendData.nachname.value,
      strasse: sendData.strasse.value,
      plz: sendData.plz.value,
      email: sendData.email.value,
      akustiker: sendData.akustiker.value,
      date
    };

    if (
      data.code != "" &&
      data.veranstaltungsort != "-- Veranstaltungsort --" &&
      data.vorname != "" &&
      data.nachname != "" &&
      data.ort != "" &&
      data.strasse != "" &&
      data.email != "" &&
      sendData.consent.checked &&
      sendData.plz.value.length != 0 &&
      sendData.emailHP.value === ""
    ) {
      if (
        sendData.plz.value.length !== 5 ||
        isNaN(parseInt(sendData.plz.value))
      ) {
        errorMsg.innerHTML = "<strong>Bitte gültige PLZ angeben!</strong>";
      } else {
        errorMsg.innerHTML = "";
        console.log(data);
        sendData.reset();
        modal.style.display = "none";
        klavierForm.reset();
        document.body.classList.remove("modal--active");
        const ortCollection = data.veranstaltungsort.split(" – ")[0];
        axios
          .post(
            `https://oticon-klassik-hoerwochen.firebaseio.com/data/${ortCollection}.json`,
            data
          )
          .then(res => {
            console.log(res);

            Keyboard.resetIndex();
            Keyboard.successNotification("klavier--form");
          })
          .catch(err => console.log(err));
      }
    } else {
      errorMsg.innerHTML = "<strong>Bitte alle Felder ausfüllen!</strong>";
    }
  });
}
