// if (document.getElementById("keyvisual")) {
//   const visual = document.getElementById("keyvisual");

//   window.addEventListener("scroll", e => {
//     visual.style.transform = `scale(${1 - window.pageYOffset / 500})`;
//     visual.style.opacity = `${1 - window.pageYOffset / 500}`;
//   });
// }

const nav = document.querySelector("nav");
window.addEventListener("scroll", e => {
  window.pageYOffset > 200
    ? nav.classList.add("scrolled")
    : nav.classList.remove("scrolled");
});
