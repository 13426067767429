const burger = document.getElementById("burger");
const nav = document.querySelector(".main--nav");
const navItem = document.querySelectorAll(".main--nav-item");
const preisNav = document.getElementById("preisNav");
const arrowDown = document.querySelector(".arrow--down");

burger.addEventListener("click", e => {
  document.body.classList.toggle("active");
});

navItem.forEach(nav => {
  nav.addEventListener("click", e => {
    document.body.classList.remove("active");
  });
});

preisNav.addEventListener("click", function() {
  console.log(this);
  this.classList.add("active");
});

if (document.querySelectorAll(".arrow--down").length > 0) {
  arrowDown.addEventListener("click", function() {
    preisNav.classList.add("active");
  });
}
